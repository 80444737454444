import App from "../../services/App/App.js";
import Catalog from "./SlsCatalog.js";
import ModelBody from "./SlsModel_Body.js";
import ModelDelete from "./SlsModel_Delete.js";
import ModelDetails from "./SlsModel_Details.js";
import ModelEdit from "./SlsModel_Edit.js";
import ModelExportList from "./SlsModel_ExportList.js";
import ModelNew from "./SlsModel_New.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  SalesItem: ModelBody.SalesItem,
  // other
  Client: ModelBody.Client,
  Invoice: ModelBody.Invoice,

  /*** property ***/

  Access: ModelBody.Access,
  DiscType: ModelBody.DiscType,
  IsSelfService: ModelBody.IsSelfService,
  Search: ModelBody.Search,
  Status: ModelBody.Status,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails(ModelBody.DiscType);
  },
  createItem() {
    return ModelBody.createItem();
  },

  createIsSelfServiceOptions(optionAllText) {
    return ModelBody.createIsSelfServiceOptions(optionAllText,
      ModelBody.IsSelfService
    );
  },
  createStatusOptions(optionAllText) {
    return ModelBody.createStatusOptions(optionAllText, ModelBody.Status);
  },
  createDiscTypeOptions(optionAllText) {
    return ModelBody.createDiscTypeOptions(optionAllText, ModelBody.DiscType);
  },

  setDetailsByClient(userData, clientData) {
    ModelBody.setDetailsByClient(userData, clientData, ModelBody.DiscType);
  },
  setDetailsByStatus(userData) {
    ModelBody.setDetailsByStatus(userData, ModelBody.Status);
  },
  normalizeDetailsByDisc(userData) {
    ModelBody.normalizeDetailsByDisc(userData);
  },

  setItemErrors(userItem, field, errors) {
    ModelBody.setItemErrors(userItem, field, errors);
  },
  setItemByPrice(userItem, priceData) {
    ModelBody.setItemByPrice(userItem, priceData);
  },
  setItemByStock(userItem, stockData, itemData) {
    ModelBody.setItemByStock(userItem, stockData, itemData);
  },

  clearDetailsByClient(userData) {
    ModelBody.clearDetailsByClient(userData);
  },

  updateDetails(userData, userItems) {
    ModelBody.updateDetails(userData, userItems, ModelBody.DiscType);
  },
  updateItem(userItem) {
    ModelBody.updateItem(userItem);
  },
  updateItemByPackaging(userItem) {
    ModelBody.updateItemByPackaging(userItem);
  },
  updateItemReadOnly(userItem) {
    ModelBody.updateItemReadOnly(userItem);
  },

  validationDeliveryDate(userData) {
    return userData.DeliveryDate === null
      ? {}
      : {
        [App.Vee.Rule.MinDate]: {
          target: userData.Date,
          label: Catalog.Date.Label
        }
      };
  },

  populateData(userData, userItems) {
    let result = ModelBody.populateDetails(userData, ModelBody.DiscType);
    result.Items = ModelBody.populateItems(userItems);
    return result;
  },

  /*** method - Delete */

  Delete: {
    getMessageConfirmationSection(invoiceList, rtnInvoiceList) {
      return ModelDelete.getMessageConfirmationSection(
        invoiceList, rtnInvoiceList
      );
    },
    getInvoiceSectionHelp(invoiceList) {
      return ModelDelete.getInvoiceSectionHelp(invoiceList);
    },
    getRtnInvoiceSectionHelp(rtnInvoiceList) {
      return ModelDelete.getRtnInvoiceSectionHelp(rtnInvoiceList);
    },
    getSalesSectionHelp(salesData) {
      return ModelDelete.getSalesSectionHelp(salesData);
    },
    validationDraftNumber(salesData) {
      return ModelDelete.validationDraftNumber(salesData);
    }
  },

  /*** method - Details ***/

  Details: {
    getItemInfo(userItem) {
      return ModelDetails.getItemInfo(userItem);
    },

    getActions(id, access) {
      return App.Access.getDetailsAvailableActions(
        id, ModelBody.Actions.Details, ModelBody.Module, access
      );
    }
  },

  /*** method - Edit ***/

  Edit: {
    setDetailsByData(userData, salesData, invoiceCount) {
      ModelEdit.setDetailsByData(userData, salesData, invoiceCount,
        ModelBody.DiscType
      );
    },
    setItemsByData(userItems, salesItems) {
      ModelEdit.setItemsByData(userItems, salesItems);
    },

    getItemInfo(userItem) {
      ModelEdit.getItemInfo(userItem);
    },

    validateItem(userItem) {
      ModelEdit.validateItem(userItem);
    }
  },

  /*** method - ExportList ***/

  ExportList: {
    getPivotSummaryObj(dataListByClient) {
      return ModelExportList.getPivotSummaryObj(dataListByClient);
    }
  },

  /*** method - List ***/

  List: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.List, ModelBody.Module, access
      );
    },
    hasAccessAction(access) {
      return access.Act_Details ||
        access.Act_Edit ||
        access.Act_PrintDetails ||
        access.Act_ExportDetails;
    }
  },

  /*** method - New ***/

  New: {
    setDetailsByWarehouseList(userData, warehouseList) {
      return ModelNew.setDetailsByWarehouseList(userData, warehouseList);
    },

    getItemInfo(userItem) {
      return ModelNew.getItemInfo(userItem);
    }
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },
  getWarehouseDefault(items, defaultId) {
    return WarehouseModel.getDefault(items, defaultId);
  }
}